var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_vm._v(" some right content ")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" content "),
      _c("br"),
      _vm._v("content"),
      _c("br"),
      _c("router-link", { attrs: { to: "/design/main" } }, [
        _vm._v("main view")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/design/auth" } }, [
        _vm._v("auth view")
      ]),
      _c("br"),
      _c("router-link", { attrs: { to: "/design" } }, [
        _vm._v("back to design system")
      ]),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }